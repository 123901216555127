// Store token for AJAX requests
let csrf_token

function list_files(dzone, mocks) {
	/*dzone.on('maxfilesexceeded', function(file) {
		dzone.removeAllFiles()
		dzone.addFile(file)
	})*/

	mocks.forEach(mock => {
		let server_file = {
			server_id: mock.id,
			name: mock.filename,
			size: mock.filesize,
			accepted: true
		}

		dzone.displayExistingFile(server_file, mock.url)
	})
}


/** Autorun **/

$(function() {
	const lightbox = GLightbox()

	// Read token when page is loaded
	csrf_token = document.head.querySelector('meta[name="csrf-token"]').content
	// Set CSRF token for in the header for all AJAX requests
	$.ajaxSetup({
		beforeSend: function(xhr) {
			xhr.setRequestHeader('X-CSRF-TOKEN', csrf_token)
		}
	})

	$('#nivo-slider-container').nivoSlider({
		effect: 'slideInLeft',
		animSpeed: 500,
		pauseTime: 8000,
		directionNav: false,
		directionNavHide: true,
		controlNav: true,
		keyboardNav: false,
		pauseOnHover: false,
		captionOpacity: 1.0,
		prevText: '',
		nextText: ''
	})

	$('#green-gallery').hoverscroll({
		vertical: false,
		width: 980,
		height: 142,
		arrows: true,
		arrowsOpacity: 1.0,
		arrowsOpacityEnabled: false,
		fixedArrows: false,
		rtl: false,
		debug: false
	})

	if ($('#message-files').length > 0) {
		let drop = new Dropzone('#message-files', {
			createImageThumbnails: false,
			addRemoveLinks: false,
			maxFilesize: 4, // 4 MiB
			url: base_url + 'kapcsolat/fajl-feltoltes',
			headers: {
				'X-CSRF-TOKEN': csrf_token
			},
			previewTemplate: '<div class="file-box">' +
				'<div class="dz-image">' +
				//'<img data-dz-thumbnail />' +
				'<div class="dz-filename"><span data-dz-name></span></div>' +
				'<div class="dz-size" data-dz-size></div>' +
				'<div data-dz-remove class="remove-link">törlés</div>' +
				'</div>' +
				'</div>',
			init: function() {
				let dz = this

				$.ajax({
					url: base_url + 'kapcsolat/fajlok',
					method: 'post',
					data: { list: 1 },
					dataType: 'json',
					success: function(data) {
						if (data) {
							list_files(dz, data)
						}
					},
					error: function(data) {
						console.error(data)
					}
				})
			}
		})

		// Assign ID after server accepted and stored the file
		drop.on('success', function(file, response) {
			file.server_id = response.id
		})

		// Remove file from the server, too
		drop.on('removedfile', function(file) {
			$.ajax({
				url: base_url + 'kapcsolat/fajl-torles',
				method: 'post',
				data: {
					file_id: file.server_id
				},
				dataType: 'json',
				success: function(data) {
					if (!data.success) {
						mbox('Fájl törlése', 'A fájl törlése nem sikerült!')
					}
				},
				error: function(data) {
					console.error(data)
				}
			})
		})
	}

})
